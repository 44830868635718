import React from 'react';
import { capitalizeOnlyFirstLetter } from 'corporate-utils';
import { getStrapiMedia } from '../../../utils/media';
import { SingleStrapiImage } from '../../../models/domain/interfaces';
import {
  CardStyled,
  Category,
  ContentWrapper,
  CTAWrapper,
  Description,
  Icon,
  ImgWrapper,
  CTA,
} from './CardServiceOffer.style';
import router from 'next/router';
import Link from 'next/link';

interface IProps {
  data: {
    category: string;
    title: string;
    description: string;
    button: any;
    image: SingleStrapiImage;
    overlayColor: string;
    type: string;
  };
  section: string;
}

const CardServiceOffer: React.FC<IProps> = ({ data, section }: IProps) => {
  const { description, button, image, title } = data ?? {};

  const handleClick = (e: any) => {
    e.preventDefault();
    if (button.url) {
      router.push(
        { pathname: button.url },
        { pathname: button.url },
        { locale: router.locale, shallow: false }
      );
    }
  };

  return (
    <CardStyled
      onClick={(e: any) => {
        handleClick(e);
      }}
    >
      <ImgWrapper>
        {image && image.data && image.data.attributes && (
          <Icon src={getStrapiMedia(image?.data?.attributes?.url)} />
        )}
      </ImgWrapper>
      <ContentWrapper>
        {title && <Category>{title}</Category>}
        {description && <Description>{description}</Description>}
        {button && button.url && (
          <CTAWrapper>
            <Link href={button.url} passHref>
              <CTA section={section}>
                {capitalizeOnlyFirstLetter(button.text)}
              </CTA>
            </Link>
          </CTAWrapper>
        )}
      </ContentWrapper>
    </CardStyled>
  );
};

export default CardServiceOffer;
