import { styled } from '@mui/material';
import { ContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  marginBottom: theme.spacings.unit(16),
}));

export const MainRepeater = styled('div')(({ theme }) => ({
  margin: `${theme.spacings.unit(20)} 0 0 0`,
  display: 'flex',
  flexDirection: 'row',
  flexAlign: 'center',
  justifyContent: 'center',
}));
