import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';
import SVG from 'react-inlinesvg';

export const CardStyled = styled('div')(({ theme }) => ({
  height: '100%',
  border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
  padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(4)}`,
  letterSpacing: theme.spacings.unit(0.15),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.colors.primary,
    '*': {
      color: theme.colors.white,
    },
    svg: {
      fill: theme.colors.white,
      'polyline, path': {
        stroke: theme.colors.white,
      },
    },
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    flexDirection: 'row',
    padding: `${theme.spacings.unit(6)} ${theme.spacings.unit(6)}`,
  },
}));

export const ImgWrapper = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.up('md')]: {
    width: '30px',
    maxWidth: '30px',
    margin: `0 ${theme.spacings.unit(4.5)} 0 0`,
    flexGrow: '1',
  },
}));

export const Icon = styled(SVG)(({ theme }) => ({
  width: '30px',
  height: '30px',
  aspectRatio: '1/1',
  fill: theme.colors.primary,
  'polyline, path': {
    stroke: theme.colors.primary,
  },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  flexGrow: '1',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '90%',
}));

export const Category = styled('h2')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(10),
  textTransform: 'uppercase',
  fontWeight: 'normal',
  color: theme.colors.primary,
  margin: `${theme.spacings.unit(2)} 0 ${theme.spacings.unit(4)} 0`,
  [theme.breakpointsMediaQuery.up('md')]: {
    margin: `0 0 ${theme.spacings.unit(4)} 0`,
    fontSize: theme.fonts.utility.size(8),
  },
}));

export const Description = styled('h3')(({ theme }) => ({
  display: 'block',
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 'normal',
  color: theme.colors.secondaryText,
  margin: `${theme.spacings.unit(1)} 0`,
  flexGrow: '2',
}));

export const CTAWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'flex-end',
}));

export const CTA = styled(StyledLink)(({ theme, section }) => ({
  fontSize: theme.fonts.utility.size(6),
  fontWeight: 'normal',
  color: theme.colors.primary,
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(9),
  },
}));
